import { useState, useEffect } from 'react'
import { fetchVatSets } from './vatsets_api'
import {
  localStorage_getItem,
  localStorage_setItem,
} from '../utils/LocalStorageHelper'
import { daysIntoYear } from '../utils/utils'

export const getStorageIdentifier = () => {
  return process.env.STORAGE_PREFIX + '_deliverycountries_day' + daysIntoYear()
}

export const getVatSets = () => {
  const localStorageIdVatSets: string = getStorageIdentifier()

  const dataFromLocalStorage = localStorage_getItem(localStorageIdVatSets)

  if (typeof dataFromLocalStorage == 'string') {
    return JSON.parse(dataFromLocalStorage)
  }

  return []
}

export const useVatSetsData = () => {
  const [vatSets, setVatSets] = useState([])

  const localStorageIdVatSets: string = getStorageIdentifier()

  useEffect(() => {
    const dataFromLocalStorage = localStorage_getItem(localStorageIdVatSets)

    if (typeof dataFromLocalStorage == 'string') {
      setVatSets(JSON.parse(dataFromLocalStorage))
    } else {
      fetchVatSets().then(response => {
        setVatSets(Object.values(response.data))
        localStorage_setItem(
          localStorageIdVatSets,
          JSON.stringify(Object.values(response.data)),
        )
      })
    }
  }, [localStorageIdVatSets])

  return vatSets
}
