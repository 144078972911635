import { isBrowser } from '../utils/utils'
import { CartItem, ProductMaterial } from '../types'
import { addDirty } from './dirty_hook'
import { localStorage_setItem } from '../utils/LocalStorageHelper'

export const _getLocalStorageIdCartItems = () => {
  return process.env.STORAGE_PREFIX + '_cartitems'
}
export const _setCartItems = (cartItems: CartItem[]) => {
  addDirty('cart')

  const result = localStorage_setItem(
    _getLocalStorageIdCartItems(),
    JSON.stringify(cartItems),
  )
  return result
}

export const clearCartItems = () => {
  _setCartItems([])
}

export const addCartItem = (cartItem: CartItem) => {
  const cartItems = getCartItems()

  if (typeof cartItem.inserted === 'undefined' || cartItem.inserted === 0) {
    cartItem.inserted = Date.now()
  }

  const cartItemToPush: CartItem = {
    count: cartItem.count,
    inserted: cartItem.inserted,
    material: cartItem.material,
    materialId: cartItem.materialId,
    materialIId: cartItem.materialIId,
    motiveId: cartItem.motiveId,
    ratio: cartItem.ratio,
    customText: cartItem.customText,
  }

  cartItems.push(cartItemToPush)
  return _setCartItems(cartItems)
}

export const sameMaterialCheck = (c1: CartItem, c2: CartItem) => {
  if (
    typeof c1.materialId !== 'undefined' &&
    typeof c2.materialId !== 'undefined'
  ) {
    return c1.materialId === c2.materialId
  } else {
    return c1.materialIId === c2.materialIId
  }
}

export const removeCartItem = (cartItem: CartItem) => {
  const cartItems = getCartItems()

  _setCartItems(
    cartItems.filter((c: any) => {
      const remove =
        c.motiveId === cartItem.motiveId &&
        c.material === cartItem.material &&
        c.ratio === cartItem.ratio &&
        c.customText === cartItem.customText &&
        sameMaterialCheck(c, cartItem)

      return !remove
    }),
  )
}

export const isInCart = (cartItem: CartItem) => {
  return (
    getCartItems().filter((c: any) => {
      return (
        c.motiveId === cartItem.motiveId &&
        c.material === cartItem.material &&
        c.ratio === cartItem.ratio &&
        c.customText === cartItem.customText &&
        sameMaterialCheck(c, cartItem)
      )
    }).length > 0
  )
}

export const getCartItemCountPerProduct = (cartItem: CartItem) => {
  let count = 0
  getCartItems()
    .filter((c: CartItem) => {
      const found =
        c.motiveId === cartItem.motiveId &&
        c.material === cartItem.material &&
        c.ratio === cartItem.ratio &&
        c.customText === cartItem.customText &&
        sameMaterialCheck(c, cartItem)
      return found
    })
    .map((c: CartItem) => {
      count += c.count
    })

  return count
}

export const getCartItemsCount = () => {
  let count = 0
  getCartItems().map((c: CartItem) => {
    count += c.count
  })

  return count
}

export const getCartItems = () => {
  if (!isBrowser()) {
    return []
  }

  const cartItemsLocalStorage = localStorage.getItem(
    _getLocalStorageIdCartItems(),
  )

  if (typeof cartItemsLocalStorage == 'string') {
    const result = JSON.parse(cartItemsLocalStorage)

    const returnVal = result
      .filter((cartItem: any) => {
        const remove = isNaN(cartItem.ratio)
        return !remove
      })
      .sort((a: CartItem, b: CartItem) => {
        return a.inserted < b.inserted ? -1 : 1
      })

    return returnVal
  } else {
    return []
  }
}

// Example of old materialId:  [{"count":1,"inserted":1633945097587,"material":"poster","materialId":"6044","motiveId":61638,"ratio":6,"customText":""}]
// Example of new materialIId: [{"count":1,"inserted":1633945097587,"material":"poster","motiveId":61638,"ratio":6,"customText":"","materialIId":2}]

export const fixCartItems = (
  cartItems: CartItem[],
  materialData: ProductMaterial[],
) => {
  const result: CartItem[] = []

  let fixedSomething = false

  cartItems.map((cartItem: CartItem) => {
    if (typeof cartItem.materialId !== 'undefined') {
      const materials = materialData.filter((material: ProductMaterial) => {
        // Is iid set?
        if (typeof material.iid === 'number') {
          // Compare against old id
          return cartItem.materialId === material.legacy_id
        } else {
          return false
        }
      })
      fixedSomething = true

      if (materials.length == 1) {
        cartItem.materialId = undefined
        cartItem.materialIId = materials[0].iid
      }
    }

    result.push(cartItem)
  })

  if (fixedSomething) {
    _setCartItems(result)
  }

  return result
}

export const mapProductsToFetch = (cartItems: CartItem[]) => {
  const productsToFetch: any = []
  cartItems.map((cartItem: CartItem) => {
    productsToFetch.push({
      material: cartItem.material,
      motiveId: '' + cartItem.motiveId,
      ratio: '' + cartItem.ratio,
    })
  })

  return productsToFetch
}
