import {
  mapInternalKey2Slug,
  mapInternalProductMaterial2InternalKey,
  createHashUrl,
} from './UrlUtils'
import { ProductMaterial, GlobalSale, Session } from '../types'
import { getCartCountry, getCurrency } from './CountrySelection'
import {env} from '../../environment'

export const _getMainFolder = (materialName: string, searchQuery: string) => {
  const result =
    searchQuery === ''
      ? mapInternalProductMaterial2InternalKey(materialName)
      : 'search'

  return result
}
export const _getSubFolder = (materialName: string) => {
  return materialName
}

export const _createHashUrl = (hash: any) => {
  return createHashUrl(hash)
}

export const getProductsTemplateUrl = (
  materialName: string,
  categories: string[],
  sortingOrder: string,
  searchQuery: string,
  page?: number,
  variant?: string,
  lang?: string,
) => {
  let result = '/'
  result += mapInternalKey2Slug(_getMainFolder(materialName, searchQuery), lang)
  result += '/' + mapInternalKey2Slug(_getSubFolder(materialName), lang)

  if (categories.length > 0) {
    result += '/' + encodeURIComponent(categories.join('_'))
  } else {
    result += '/' + mapInternalKey2Slug('all', lang)
  }

  if (sortingOrder == 'new-in') {
    result += '/' + mapInternalKey2Slug('new-in', lang)
  } else if (sortingOrder == 'rising-stars') {
    result += '/' + mapInternalKey2Slug('rising-stars', lang)
  } else if (sortingOrder == 'relevance') {
    result += '/' + mapInternalKey2Slug('relevance', lang)
  } else {
    result += '/' + mapInternalKey2Slug('bestseller', lang)
  }

  if (searchQuery.length > 0) {
    result += '/' + encodeURIComponent(searchQuery)
  }

  if (typeof page !== 'undefined' && page > 1) {
    result += '/' + page
  }

  if (variant) {
    result += '' + _createHashUrl({ variant: variant })
  }

  return result
}
export const getProductsTemplateUrl_Page = (
  materialName: string,
  categories: string[],
  sortingOrder: string,
  searchQuery: string,
  variant?: string,
) => {
  let result = '/'
  result += mapInternalKey2Slug(_getMainFolder(materialName, searchQuery))
  result += '/' + mapInternalKey2Slug(_getSubFolder(materialName))

  if (categories.length > 0) {
    result += '/' + categories.join('_')
  } else {
    result += '/' + mapInternalKey2Slug('all')
  }

  if (sortingOrder == 'new-in') {
    result += '/' + mapInternalKey2Slug('new-in')
  } else if (sortingOrder == 'rising-stars') {
    result += '/' + mapInternalKey2Slug('rising-stars')
  } else {
    result += '/' + mapInternalKey2Slug('bestseller')
  }

  if (searchQuery.length > 0) {
    result += '/' + encodeURIComponent(searchQuery)
  }

  result += '/%d'

  if (variant) {
    result += '' + _createHashUrl({ variant: variant })
  }

  return result
}

export const getProductsTemplateUrl_SortingOrder = (
  materialName: string,
  categories: string[],
  page: number,
  searchQuery: string,
  variant?: string,
) => {
  let result = '/'
  result += mapInternalKey2Slug(_getMainFolder(materialName, searchQuery))
  result += '/' + mapInternalKey2Slug(_getSubFolder(materialName))

  if (categories.length > 0) {
    result += '/' + categories.join('_')
  } else {
    result += '/' + mapInternalKey2Slug('all')
  }

  result += '/%s'

  if (searchQuery.length > 0) {
    result += '/' + encodeURIComponent(searchQuery)
  }

  if (page > 1) {
    result += '/' + page
  }

  if (variant) {
    result += '' + _createHashUrl({ variant: variant })
  }

  return result
}

export const getProductsTemplateUrl_Categories = (
  materialName: string,
  sortingOrder: string,
  page: number,
  searchQuery: string,
  variant?: string,
) => {
  let result = '/'
  result += mapInternalKey2Slug(_getMainFolder(materialName, searchQuery))
  result += '/' + mapInternalKey2Slug(_getSubFolder(materialName))

  result += '/%s'

  if (sortingOrder == 'new-in') {
    result += '/' + mapInternalKey2Slug('new-in')
  } else if (sortingOrder == 'rising-stars') {
    result += '/' + mapInternalKey2Slug('rising-stars')
  } else {
    result += '/' + mapInternalKey2Slug('bestseller')
  }

  if (searchQuery.length > 0) {
    result += '/' + encodeURIComponent(searchQuery)
  }

  if (page > 1) {
    result += '/' + page
  }

  if (variant) {
    result += '' + _createHashUrl({ variant: variant })
  }

  return result
}

export const isTouchDevice = () => {
  return (
    isBrowser() && ('ontouchstart' in window || 'onmsgesturechange' in window)
  )
}

export const isBrowser = () => {
  return typeof window !== 'undefined' && window
}

export const _formatThousandsWithRounding = (n: number, dp: number) => {
  const w = n.toFixed(dp)
  const k = w | 0
  const b = n < 0 ? 1 : 0
  const u = Math.abs(w - k)
  const d = ('' + u.toFixed(dp)).substr(2, dp)
  const s = '' + k
  let i = s.length
  let r = ''

  while ((i -= 3) > b) {
    r = '.' + s.substr(i, 3) + r
  }

  return s.substr(0, i + 3) + r + (d ? ',' + d : '')
}

export const daysIntoYear = () => {
  const date = new Date()
  return (
    (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(date.getFullYear(), 0, 0)) /
    24 /
    60 /
    60 /
    1000
  )
}

export const formatDate = (date: string) => {
  const elements = date.split('-')

  if (elements.length != 3) {
    return '-'
  } else if (env.getLanguage() == 'de') {
    return elements[2] + '.' + elements[1] + '.' + elements[0]
  } else {
    return elements[0] + '-' + elements[1] + '-' + elements[2]
  }
}

export const formatPrice = (price: number) => {
  const country = getCartCountry()
  const currency = getCurrency(country)

  if (currency == '€') {
    return _formatThousandsWithRounding(price, 2) + ' ' + currency
  } else {
    return currency + ' ' + _formatThousandsWithRounding(price, 2)
  }
  // return price.toLocaleString(localeString) + ' ' + currency
}

export const getMaterialGroupDefaults = (materialgroup: string) => {
  if (materialgroup == 'poster_plastic') {
    return 'poster_black_plastic'
  } else if (materialgroup == 'poster_metal_frame') {
    return 'poster_kupfer'
  } else if (materialgroup == 'poster_wooden_frame') {
    return 'poster_schwarz'
  } else if (materialgroup == 'cases') {
    return 'case_premium_iphone'
  } else {
    return ''
  }
}

// Show MaterialGroup Button...
export const getMaterialGroups = () => {
  return [
    'poster_plastic',
    'poster_metal_frame',
    'poster_wooden_frame',
    'cases',
    'tshirt',
    'hoodie',
    'sweatshirt',
  ]
}

export const chooseDefaultMaterial = (
  materials: ProductMaterial[],
  index: number,
) => {
  let chosenElement = materials[0]

  if (
    chosenElement.materialgroup != 'cups' &&
    chosenElement.materialgroup != 'cases' &&
    chosenElement.materialgroup != 'tshirt' &&
    chosenElement.materialgroup != 'hoodie' &&
    chosenElement.materialgroup != 'sweatshirt' &&
    materials.length >= index + 1
  ) {
    chosenElement = materials[index]
  }

  let defaultMaterial = ''

  if (getMaterialGroups().indexOf(chosenElement.materialgroup) !== -1) {
    defaultMaterial = getMaterialGroupDefaults(chosenElement.materialgroup)
  }

  if (defaultMaterial != '') {
    materials = materials.filter((material) => {
      return material.material == defaultMaterial
    })

    if (materials.length >= index + 1) {
      chosenElement = materials[index]
    } else if (materials.length > 0) {
      chosenElement = materials[0]
    }
  }

  return chosenElement
}

export const serialize = (obj: any, prefix?: any) => {
  let str = [],
    p

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p]
      str.push(
        v !== null && typeof v === 'object'
          ? serialize(v, k)
          : encodeURIComponent(k) + '=' + encodeURIComponent(v),
      )
    }
  }

  return str.join('&')
}

export const serializeToForm = (
  obj: any,
  prefix?: any,
  formdata?: undefined | FormData,
) => {
  if (typeof formdata === 'undefined') {
    formdata = new FormData()
  }

  let p

  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p]

      if (v !== null && typeof v === 'object') {
        serializeToForm(v, k, formdata)
      } else {
        formdata.append(k, v)
      }
    }
  }

  return formdata
}

export const validateMailaddress = (mailAddress: string) => {
  const m = mailAddress
  let result = false

  if (/^\w+([+.-]\w+)*@\w+([.-]\w+)*\.\w{2,20}$/.test(m)) {
    result = true
  }

  return result
}

export const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max))
}

export const reduceKeysFromObjectArray = (arrayToReduce: [], KeysToKeep: any) => {
  return arrayToReduce.map(o => KeysToKeep.reduce((acc: [], curr: any) => {
      acc[curr] = o[curr];

      return acc;
  }, {}));
}
