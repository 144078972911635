import { createTheme } from '@mui/material'
import { grey } from '@mui/material/colors'

// https://mui.com/material-ui/customization/color/
// lightBlue[700]

const interpolate = (begin: number, end: number, step: number, max: number) => {
  if (begin < end) {
    return (end - begin) * (step / max) + begin
  } else {
    return (begin - end) * (1 - step / max) + end
  }
}

const d2h = (d: number) => {
  const h = parseInt('' + d, 10).toString(16)
  return h.length === 1 ? '0' + h : h
}

const calculateColor = () => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  const a = new Date()

  const utc1 = Date.UTC(2022, 5, 10)
  const utc2 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())

  const difference = Math.floor((utc2 - utc1) / _MS_PER_DAY)

  const steps = 180

  if (difference <= 0) {
    return '#00aaff'
  } else if (difference >= steps) {
    return '#00897B'
  } else {
    const r0 = 0
    const g0 = 170
    const b0 = 255

    const r1 = 0
    const g1 = 137
    const b1 = 123

    const r = interpolate(r0, r1, difference, steps)
    const g = interpolate(g0, g1, difference, steps)
    const b = interpolate(b0, b1, difference, steps)

    return '#' + d2h(r) + d2h(g) + d2h(b)
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: calculateColor(),
      contrastText: '#ffffff',
    },
    secondary: {
      main: grey[300],
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
})

export default theme
